<template>
  <div>
    <b-card
      :title="$t('members')"
      class="mb-5"
    >
      <b-input-group
        v-if="displayCompanyOption"
        class="mb-1"
      >
        <b-input-group-prepend is-text>
          <span
            style="min-width: 95px;"
            v-text="$t('company')"
          />
        </b-input-group-prepend>
        <b-form-select
          v-model="company"
          :options="companies"
          @change="loadCompany"
        />
      </b-input-group>
      <div class="vgt-wrap">
        <div class="vgt-inner-wrap">
          <div class="vgt-responsive">
            <table class="vgt-table bordered ">
              <thead>
                <tr>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('account')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('nickname')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('agent')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('room_number')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('last_login')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('ip')" />
                  </th>
                  <th
                    class="vgt-left-align"
                    style="min-width: auto; width: auto;"
                  >
                    <span v-text="$t('balance')" />
                  </th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="member in onlineMembers"
                >
                  <tr
                    v-if="typeof members[member.meta.username] !== 'undefined'"
                    :key="member.meta.id"
                  >
                    <td class="vgt-left-align">
                      {{ member.meta.username }}
                    </td>
                    <td class="vgt-left-align">
                      {{ members[member.meta.username].nickname }}
                    </td>
                    <td class="vgt-left-align">
                      {{ members[member.meta.username].agent }}
                    </td>
                    <td class="vgt-left-align">
                      <template v-if="typeof member.number !== 'undefined'">
                        <span
                          v-if="typeof tables[`port_${member.number}`] !== 'undefined'"
                        >
                          {{ tables[`port_${member.number}`] }}
                        </span>
                        <span
                          v-else
                          v-text="$t('lobby')"
                        />
                      </template>
                    </td>
                    <td class="vgt-left-align">
                      {{ members[member.meta.username].login_time }}
                    </td>
                    <td class="vgt-left-align">
                      {{ members[member.meta.username].ip }}
                    </td>
                    <td class="vgt-left-align">
                      {{ Number(members[member.meta.username].game_balance).toLocaleString() }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>

import { io } from 'socket.io-client'
import Account from '@/models/Account'

import {
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
} from 'bootstrap-vue'

import { EventBus } from '../../../eventbus'

import 'vue-good-table/dist/vue-good-table.css'

export default {
  name: 'OnlineUser',
  components: {
    BCard,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
  },
  data() {
    return {
      socket: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      members: [],
      onlineMembers: [],
      companies: [],
      company: null,
      tables: [],
      displayCompanyOption: false,
    }
  },
  mounted() {
    if (this.userData.role === 'company') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          companyId: this.userData.id,
        },
      })
    } else if (this.userData.role === 'agent') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          parentId: this.userData.id,
        },
      })
    } else if (this.userData.role === 'distributor') {
      this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
        secure: true,
        query: {
          distributorId: this.userData.id,
        },
      })
    }
    if (this.userData.role === 'company' || this.userData.role === 'agent' || this.userData.role === 'distributor') {
      this.socket.on('online_user_list', data => {
        this.onlineMembers = data
      })
    }
    EventBus.$emit('getMyUpdatedUserData') // Todo: Need to refactor
    this.loadMembers()
    if (this.userData.role === 'admin') {
      this.loadCompanies()
    }
  },
  methods: {
    async loadMembers() {
      const response = await Account.memberList()
      this.members = response.data.data
      this.tables = response.data.tables
    },
    async loadCompanies() {
      const response = await Account.getCompanyList()
      this.companies = response.data.data
      this.company = response.data.meta.company
      this.displayCompanyOption = true
      this.loadCompany()
    },
    loadCompany() {
      if (this.userData.role === 'admin') {
        if (typeof this.socket.connected !== 'undefined' && this.socket.connected === true) {
          this.socket.disconnect()
        }
        this.socket = io(`${process.env.VUE_APP_MIX_SOCKET_URL}/agent`, {
          secure: true,
          query: {
            companyId: this.company,
          },
        })
        this.socket.on('online_user_list', data => {
          console.log(data) // eslint-disable-line
          this.onlineMembers = data
        })
      }
    },
  },
}
</script>

<style>

</style>
